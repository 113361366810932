import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Helmet from './Helmet'

const TemplateWrapper = ({ children }) => {
  return (
    <div>
      <Helmet />
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
